import { query } from "atomic-layout"
import { createGlobalStyle, css } from "styled-components"
import { collapseWidth } from "../components/organisms/Navigation"
import { styledScaledHeadings } from "../utils/typography"

export const SROnly = css`
  position:absolute;
  left:-10000px;
  top:auto;
  width:1px;
  height:1px;
  overflow:hidden;
`

export const GlobalStyles = createGlobalStyle`
  :root {
    --max-body-width: 1440px;
    --section-spacing: 8rem;
    --section-side-padding: 1.5rem;
    --gutter: 1.25rem;
    --menu-item-spacing: 1.5rem;
    --base-font-size-percent: 100%;
    --line-height: 1.5;
    --input-bottom-margin: 40px;
    --body-font-family: "Greycliff CF", system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    --heading-font-family: "Greycliff CF", system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    --body-text-color: #262626;
    --body-text-color-secondary: #3E2B2E;
    --roundness: 15px;
    --primary-color: ${({ theme }) => theme.primaryColor};
    --secondary-color: ${({ theme }) => theme.secondaryColor};
    --tertiary-color: ${({ theme }) => theme.tertiaryColor};
    //--modular-scale-ratio: 1.2;
    --heading-margin-block-end: 0.3em;
    --link-color: ${({ theme }) => theme.primaryColor};
    --backdrop-offset: 32px;

    @media ${query({ from: "md" })} {
      --section-spacing: 8rem;
      //--section-side-padding: 4.5rem;
    }

    @media ${query({ from: "lg" })} {
      --section-spacing: 10rem;
      --column-gap-rem: 10rem;
      --backdrop-offset: 72px;
    }
  }

  html {
    font-size: 16px;
  }

  body {
    margin: 0 auto;
    //max-width: var(--max-body-width);
    //overflow-x: hidden;
    font-family: var(--body-font-family);
  }

  .sb-show-main.sb-main-fullscreen{
    margin: 0 auto !important;
  }

  body.has-banner {
    // This fixes the issue where the top banner can cover the anchor headings
    // TODO check for fixed header
    h1::before, h2::before, h3::before {
      display: block;
      content: " ";
      margin-top: -60px;
      height: 60px;
      visibility: hidden;
      pointer-events: none;
    }
  }

  * {
    box-sizing: border-box;
  }

  a {
    color: var(--link-color);
    text-decoration: none;
  }

  img {
    display: block;
    width: 100%;
  }

  p {
    line-height: var(--line-height);
    margin-block-start: 0;
    font-size: 1.4375rem;
    margin: 2.125rem 0;
  }

  p:last-child, ul:last-child {
    margin-bottom: 0;
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: var(--heading-font-family);
    line-height: 1.5em;
    margin: 0;
    margin-block-end: var(--heading-margin-block-end);
  }

  ${styledScaledHeadings()}
  
  h1,h2,h3{
    line-height: 120%;
  }
  
  h2, h3{
    margin-bottom: 2rem;
  }

  ${[1,2,3,4,5,6].reduce((prev, val) => `${prev}p ~ h${val}, ul ~ h${val}, ol ~ h${val},`, '').replace(/(^,)|(,$)/g, "")}{
    margin-top: calc(var(--line-height) * 4rem);
  }

  .caret {
    &:after {
      content: url(/images/caret-mobile.svg);
      margin-left: 8px;

      @media (min-width: ${collapseWidth}) {
        content: url(/images/caret.svg);
      }
    }
  }
  
  fieldset {
    border: none;
    padding: 0;
    margin: 0;
  }

  label:not(.hs-form-checkbox-display):not(.hs-error-msg) {
    display: inline-block;
    margin-bottom: 1rem;
    font-weight: bold;
    font-size: 28px;
    line-height: 150%;
  }

  input:not([type='submit']):not([type='checkbox']), select, textarea{
    line-height: 120%;
    font-size: 1.125rem;
    padding: 1rem 1.5rem;
    border-radius: 10px;
    width: 100%;
    border: 1px solid #515151;
    
    &:focus{
      outline: none;
      box-shadow: 0 0 5px #99cdc1;
      border: 1px solid ${({theme}) => theme.primaryColor};
    }
  }
  
  .form-group{
    margin-bottom: var(--input-bottom-margin);
  }
  
  ul, ol {
    margin-top: 0;
    font-size: 23px;
    line-height: 150%;

    ul {
      margin-bottom: 0;
    }
  }

  input[type=checkbox] {
    vertical-align: middle;
    position: relative;
    bottom: 1px;
  }

  pre {
    margin-top: 0;
    margin-bottom: 1rem;
  }

  #gatsby-focus-wrapper {
    position: relative;
    //overflow: hidden;

    @media ${query({from: 'md'})} {
      //overflow: visible;
    }
  }

  .sr-only {
    ${SROnly}
  }
  
  div[name="termly-embed"]{
      max-width: 720px;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 64px;
  }
`
